<template>
  <div>
    <section>
      <Hero>
        <div class="container">
          <HeadText :titletext="'Mein Zweitmarkt'" :subtext="'Ihr Handelsplatz für geschlossene Fondsbeteiligungen'"></HeadText>
        </div>
        <div class="container bg-light">
          <div class="alert alert-warning" role="alert">
            <p>Das Verfahren für das Anfordern eines neuen Passwortes hat sich geändert. Nutzen Sie bitte erneut die 
              Seite <router-link to="/resetpassword" class="nav-link">Passwort anfordern</router-link></p>
          </div>
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
export default {
  name: "SetPasswordUpdate"
}
</script>

<style scoped>

</style>